<template>
  <div>
    <div v-if="passVariableModuleName == 'm_yuki'">
      <span v-for="(betType, i) in item.bet_type" :key="i">
        {{ betType.name ? checkbetType(item.channel_type, betType.name) : '' }}
      </span>
    </div>
    <div v-else-if="passVariableModuleName == 'm_lottery'">
      <LotteryBettingType :item="item" :passVariableModuleName="passVariableModuleName"></LotteryBettingType>
    </div>
    <div v-else-if="passVariableModuleName == 'm_klaklouk'"
      style="font-family: 'Koh Santepheap', cursive, serif , cursive, Nunito, Segoe UI, sans-serif">
      <KlakloukBettingType :item="item" :passVariableModuleName="passVariableModuleName"></KlakloukBettingType>
    </div>
    <td v-else class="w-100 text-center mx-auto">
      <div v-if="passVariableModuleName == 'Yuki'" class="d-flex justify-content-start">
        <div class="align-items-between">
          <span color="warning" class="my-auto">
            {{ item.bet_type.name ? checkbetType(item.channel_type, item.bet_type.name) : '' }}
          </span>
        </div>
      </div>

      <div v-else class="w-100 text-center">

        <div style="font-family: 'Koh Santepheap', cursive, serif , cursive, Nunito, Segoe UI, sans-serif"
          class="align-items-between text-center justify-content-center w-100 ">

          <span class="text-center white--text justify-content-center "
            style="font-family: 'Koh Santepheap', cursive, serif , cursive, Nunito, Segoe UI, sans-serif!important">
            <span v-if="passVariableModuleName == 'm_lottery3' || passVariableModuleName == 'm_lotteryvn'">
              <span v-if="item.bet_a.number.length > 0"> {{ 'A: ' + item.bet_a.number.toString() }}<br></span>
              <span v-if="item.bet_b.number.length > 0"> {{ 'B: ' + item.bet_b.number.toString() }}<br></span>
              <span v-if="item.bet_c.number.length > 0"> {{ 'C: ' + item.bet_c.number.toString() }}<br></span>
              <span v-if="item.bet_d.number.length > 0"> {{ 'D: ' + item.bet_d.number.toString() }}<br></span>
              <span v-if="item.bet_e.number.length > 0"> {{ 'E: ' + item.bet_e.number.toString() }}<br></span>
              <span v-if="item.bet_lo.number.length > 0"> {{ 'LO: ' + item.bet_lo.number.toString() }}<br></span>
            </span>
            <span v-else>
              {{ item.bet_type ? checkbetType(channel_type, item.bet_type.name) : "--" }}
            </span>


          </span>
        </div>
      </div>
    </td>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import moment from "moment";

import KlakloukBettingType from "./BettingTypeComponent/KlakloukBettingType.vue"
import LotteryBettingType from "./BettingTypeComponent/LotteryBettingType.vue"
import gFunction from "~mixin/MX_ShareFunctionCollection.js";


export default {
  components: {
    KlakloukBettingType,
    LotteryBettingType
  },
  mixins: [gFunction],
  props: [
    'item', 'passVariableModuleName', 'channel_type'
  ],
  data() {
    return {


      dataDetail: {
        rows_per_page: 200,
        page: 1,
        end_date: "",
        start_date: "",
      },
    };
  },
  computed: {
    ...mapGetters("$_allstatement", ["getLoading"]),
  },
  methods: {
    moment,
    showDeail() {
      this.$emit("showDeail");
    },
    _getResultClass(x) {
      switch (x) {
        case "Banker 1":
          return "B1";
        case "Banker 2":
          return "B2";
        case "Banker 3":
          return "B3";
        case "Player 1":
          return "P1";
        case "Player 2":
          return "P2";
        case "Player 3":
          return "P3";
        case "CANCEL":
          return "Cancel";
        case "Tie 1":
          return "T1";
        case "Tie 2":
          return "T2";
        case "Tie 3":
          return "T3";
        default:
          return "";
      }
    },
  },
};
</script>
<style>
.klaklouk-img {
  text-align: center;
  margin-bottom: 4px;
  height: 35px !important;
  width: 35px !important;
  margin: auto;
}
</style>