<template>
  <div>
    {{ $t('title.pleaes_detail') }}
     
  </div>
</template>            
      

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import gFunction from "~mixin";


export default {
  props: [
    'item', 'passVariableModuleName'
  ],
  data() {
    return {
      mixins: [gFunction],

      dataDetail: {
        rows_per_page: 200,
        page: 1,
        end_date: "",
        start_date: "",
      },
    };
  },
  computed: {
    ...mapGetters("$_allstatement", ["getLoading"]),
  },
  methods: {
    moment,
    showDeail() {
      this.$emit("showDeail");
    },
  },
};
</script>
<style>
.klaklouk-img {
  text-align: center;
  margin-bottom: 4px;
  height: 35px !important;
  width: 35px !important;
  margin: auto;
}
</style>