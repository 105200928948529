const 
	detailReport = [
        { text: "date", value: "date",		width: 'min-width:170px;width:12%' },
        { text: "game", value: "no", 	width: 'min-width:170px;width:12%' },
        { text: "bettingType", value: "bet_type.name",	width: 'min-width:170px;width:12%' },
        { text: "amount", value: "total_amount",	width: 'min-width:170px;width:12%' },
        { text: "total_win_lose", value: "total_amount_win",	width: 'min-width:200px;width:12%' },
        { text: "cash", value: "total_cast",	width: 'min-width:170px;width:12%' },
        { text: "result", value: "bet_result",	width: 'min-width:170px;width:12%' },
      ]

export default detailReport;