<template>
  <div>
    <div>
      <span v-if="item.bet_a && item.bet_a.number.length > 0" :style="{ color: item.bet_a.color }">
        A
      </span>



      <span> : </span>

      <span style="font-weight: bold" v-if="item.bet_a.number.length > 0 ||
        item.bet_b.number.length > 0 ||
        item.bet_c.number.length > 0 ||
        item.bet_d.number.length > 0 ||
        item.bet_e.number.length > 0
        ">
        <span>
          {{
            item.bet_a.label != null
            ? item.bet_a.label.toString().replace(/\d{2}(?=.)/g, '$& ').substring(0, 8) + "..., "
            : " "
          }}
        </span>
        <span v-if="item.bet_b && item.bet_b.number.length > 0" :style="{ color: item.bet_b.color }">
          B
        </span>
        <span>
          {{
            item.bet_b.label != null
            ? item.bet_b.label.toString().replace(/\d{2}(?=.)/g, '$& ').substring(0, 8) + "..., "
            : " "
          }}
        </span>
        <span v-if="item.bet_c && item.bet_c.number.length > 0" :style="{ color: item.bet_c.color }">
          C:
        </span>
        <span>
          {{
            item.bet_c.label != null
            ? item.bet_c.label.toString().replace(/\d{2}(?=.)/g, '$& ').substring(0, 8) + "..., "
            : " "
          }}
        </span>
        <span v-if="item.bet_d && item.bet_d.number.length > 0" :style="{ color: item.bet_d.color }">
          D:
        </span>
        <span>
          {{
            item.bet_d.label != null
            ? item.bet_d.label.toString().replace(/\d{2}(?=.)/g, '$& ').substring(0, 8) + "..., "
            : " "
          }}
        </span>
        <span v-if="item.bet_e && item.bet_e.number.length > 0" :style="{ color: item.bet_e.color }">
          E :
        </span>
        <span>
          {{
            item.bet_e.label != null
            ? item.bet_e.label.toString().replace(/\d{2}(?=.)/g, '$& ').substring(0, 8) + "..."
            : " "
          }}
        </span>
      </span>
    </div>
  </div>
</template>            
      

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import gFunction from "~mixin";


export default {
  props: [
    'item', 'passVariableModuleName'
  ],
  data() {
    return {
      mixins: [gFunction],

      dataDetail: {
        rows_per_page: 200,
        page: 1,
        end_date: "",
        start_date: "",
      },
    };
  },
  computed: {
    ...mapGetters("$_allstatement", ["getLoading"]),
  },
  methods: {
    moment,
    showDeail() {
      this.$emit("showDeail");
    },
  },
};
</script>
<style>
.klaklouk-img {
  text-align: center;
  margin-bottom: 4px;
  height: 35px !important;
  width: 35px !important;
  margin: auto;
}
</style>