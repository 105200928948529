var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"justify-center"},[_c('v-img',{staticClass:"klaklouk-img mx-2",attrs:{"src":_vm.findImageOfEachKlaklouk(
        _vm.item.bet_result.result1_name
      ),"width":"35"}}),_c('v-img',{staticClass:"klaklouk-img ma-2",attrs:{"src":_vm.findImageOfEachKlaklouk(
        _vm.item.bet_result.result1_name
      ),"width":"40"}}),_c('v-img',{staticClass:"klaklouk-img mx-2",attrs:{"src":_vm.findImageOfEachKlaklouk(
        _vm.item.bet_result.result1_name
      ),"width":"40"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }