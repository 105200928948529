<template>
  <div class="text-center d-flex">
    <div v-if="passVariableModuleName == 'm_yuki'" class="text-center d-flex">
      <span>
        {{ $t("title.please_detail") }}
      </span>
    </div>
    <div v-else-if="passVariableModuleName == 'm_lottery'">
      <LotteryResult
        :item="item"
        :passVariableModuleName="passVariableModuleName"
      ></LotteryResult>
    </div>
    <div v-else-if="passVariableModuleName == 'm_klaklouk'">

      <KlakloukResult
        :item="item"
        :passVariableModuleName="passVariableModuleName"
      ></KlakloukResult>
    </div>
    <td v-else-if="passVariableModuleName == 'Klaklouk'" class="text-center d-flex">
      <div class="align-items-between">
        <span color="warning" class="my-auto ml-1 mb-2" v-if="item.bet_result.is_win != null " >
          {{
            item.bet_result.result1_name +
            "-" +
            item.bet_result.result2_name +
            "-" +
            item.bet_result.result3_name
          }}
        </span>
        <span v-else>
          {{ $t('title.game_processing') }}
        </span>
      </div>
    </td>

    <div
      v-else-if="
        passVariableModuleName == 'han2' || passVariableModuleName == 'han3'
      "
      class="pt-1"
    >
      {{ _getResultClass(item.bet_result.result1_name) }},
      {{ _getResultClass(item.bet_result.result2_name) }},
      {{ _getResultClass(item.bet_result.result3_name) }}
    </div>
    <td v-else class="text-center d-flex">
      <div v-if="passVariableModuleName =='m_lottery3' || passVariableModuleName == 'm_lotteryvn'" class="text-center justify-content-center mx-auto " style="display:flex">
        <div class="text-center justify-content-center mx-auto">
          <span >
            {{ $t('title.please_detail') }}
    
          </span>
        </div>
      </div>
      <div v-else >
        <div class="text-center justify-content-center">
          <span :class="'cb'+item.bet_result.color">
            {{
              item.bet_result.name ? $t(`title.` + item.bet_result.name) :    $t('title.game_processing')
            }}
          </span>
        </div>
      </div>
    </td>
  </div>
</template>            
      

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import gFunction from "../../../../../mixin";
import KlakloukResult from "./ResultsComponent/KlakloukResult.vue";
import LotteryResult from "./ResultsComponent/LotteryResult.vue";

export default {
  components: {
    KlakloukResult,
    LotteryResult,
  },
  props: ["item", "passVariableModuleName", 'channel_type'],
  data() {
    return {
      mixins: [gFunction],

      dataDetail: {
        rows_per_page: 200,
        page: 1,
        end_date: "",
        start_date: "",
      },
    };
  },
  computed: {
    ...mapGetters("$_allstatement", ["getLoading"]),
  },
  methods: {
    moment,
    showDeail() {
      this.$emit("showDeail");
    },
    _getResultClass(x) {
      switch (x) {
        case "Banker 1":
          return "B1";
        case "Banker 2":
          return "B2";
        case "Banker 3":
          return "B3";
        case "Player 1":
          return "P1";
        case "Player 2":
          return "P2";
        case "Player 3":
          return "P3";
        case "CANCEL":
          return "Cancel";
        case "Tie 1":
          return "T1";
        case "Tie 2":
          return "T2";
        case "Tie 3":
          return "T3";
        default:
          return "";
      }
    },
  },
};
</script>
<style>
.klaklouk-img {
  text-align: center;
  margin-bottom: 4px;
  height: 35px !important;
  width: 35px !important;
  margin: auto;
}
</style>