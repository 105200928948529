<template>
    <div>
      <v-img
        :src="findImageOfEachKlaklouk(item.bet_type.name)"
        width="35"
        class="klaklouk-img"
      ></v-img>
 </div>
</template>            
      

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import gFunction from "~mixin";


export default {
  props: [
  'item', 'passVariableModuleName'
  ],
  data() {
    return {
      mixins: [gFunction],
      
      dataDetail: {
        rows_per_page: 200,
        page: 1,
        end_date: "",
        start_date: "",
      },
    };
  },
  computed: {
    ...mapGetters("$_allstatement", ["getLoading"]),
  },
  methods: {
    moment,
    showDeail() {
      this.$emit("showDeail");
    },
    _getResultClass(x) {
      switch (x) {
        case "Banker 1":
          return "B1";
        case "Banker 2":
          return "B2";
        case "Banker 3":
          return "B3";
        case "Player 1":
          return "P1";
        case "Player 2":
          return "P2";
        case "Player 3":
          return "P3";
        case "CANCEL":
          return "Cancel";
        case "Tie 1":
          return "T1";
        case "Tie 2":
          return "T2";
        case "Tie 3":
          return "T3";
        default:
          return "";
      }
    },
  },
};
</script>
<style>
.klaklouk-img {
  text-align: center;
  margin-bottom: 4px;
  height: 35px !important;
  width: 35px !important;
  margin: auto;
}
</style>